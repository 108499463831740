import React from "react"
import styled from "styled-components";

import { Title2, ButtonLink, PageContainer } from "../recipes";

import Layout from "../components/Layout"
import SEO from "../components/SEO"

import logoImage from "../assets/images/logo-vertical-circle.svg";
import tileImage from "../assets/images/cupcake-pattern.png";

const HomePageContainer = styled(PageContainer)`
  text-align: center;
  align-items: center;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    z-index: -1;

    opacity: 0.2;
    background-image: url(${tileImage});
    background-size: 20%;
    background-position: top center;
  }

  @media (min-width: ${props => props.theme.breakMedium}) {
    padding-top: ${props => props.theme.spacingLarge};
  }
`;

const Logo = styled.img.attrs({
  src: logoImage,
  alt: "jonesing for cake logo"
})`
  min-height: 300px;
  width: 300px;
  margin-bottom: ${props => props.theme.spacingLarge};
`;

const Question = styled(Title2)``;

const Answers = styled.div`
  padding: ${props => props.theme.spacingTiny};

  display: flex;
  flex-direction: column;
  @media (min-width: ${props => props.theme.breakMedium}) {
    flex-direction: row;
  }
`;

const Answer = styled(ButtonLink)`
  margin: ${props => props.theme.spacingTiny};
`;

const Page = () => (
  <Layout>
    <SEO title="Home" />
    <HomePageContainer>
      <Logo />
      <Question text="welcome.question" />
      <Answers>
        <Answer to="/cupcakes">I love cupcakes</Answer>
        <Answer to="/cakes">Cake please</Answer>
        <Answer to="/cookies">Gimme cookies</Answer>
        <Answer to="/nofun">I don't like fun</Answer>
      </Answers>
    </HomePageContainer>
  </Layout>
);

export default Page;
